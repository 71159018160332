import gql from 'graphql-tag'
import { FRAGMENT_USER } from '../fragments'

export const CREATE_ANONYMOUS_USER = gql`
	mutation createAnonymousUser($codes: [String], $extraLicenceQty: Int) {
		createAnonymousUser(codes: $codes, extraLicenceQty: $extraLicenceQty) {
			ok
			errors {
				fieldName
				errors
			}
			licenceIsValid {
				isValid
				code
				error
			}
		}
	}
`
export const CREATE_USER = gql`
	mutation createUser(
		$address: LimitedString!
		$city: LimitedString!
		$codes: [String]!
		$countryId: ID!
		$email: LowerString!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$password1: LimitedString!
		$password2: LimitedString!
		$phoneNumber: LimitedString!
		$postCode: LimitedString!
		$termsOfUse: Boolean!
	) {
		createUser(
			address: $address
			city: $city
			codes: $codes
			countryId: $countryId
			email: $email
			firstName: $firstName
			lastName: $lastName
			password1: $password1
			password2: $password2
			phoneNumber: $phoneNumber
			postCode: $postCode
			termsOfUse: $termsOfUse
		) {
			ok
			user {
				...User
			}
			errors {
				fieldName
				errors
			}
		}
	}
	${FRAGMENT_USER}
`

export const LOGIN_USER = gql`
	mutation loginUser($email: LowerString!, $password: LimitedString!) {
		loginUser(email: $email, password: $password) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const UPDATE_ADMIN = gql`
	mutation updateAdmin($firstName: LimitedString!, $lastName: LimitedString!) {
		updateAdmin(firstName: $firstName, lastName: $lastName) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const UPDATE_USER = gql`
	mutation updateUser(
		$address: LimitedString!
		$city: LimitedString!
		$countryId: ID!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$phoneNumber: LimitedString!
		$postCode: LimitedString!
	) {
		updateUser(
			address: $address
			city: $city
			countryId: $countryId
			firstName: $firstName
			lastName: $lastName
			phoneNumber: $phoneNumber
			postCode: $postCode
		) {
			user {
				...User
			}
			ok
			errors {
				fieldName
				errors
			}
		}
	}
	${FRAGMENT_USER}
`
export const UPDATE_USER_EMAIL = gql`
	mutation updateUserEmail($email: LowerString!, $password: LimitedString!) {
		updateUserEmail(email: $email, password: $password) {
			user {
				email
			}
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const UPDATE_PASSWORD = gql`
	mutation updateUserPassword(
		$oldPassword: LimitedString!
		$password1: LimitedString!
		$password2: LimitedString!
	) {
		updateUserPassword(
			oldPassword: $oldPassword
			password1: $password1
			password2: $password2
		) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const LOGOUT_USER = gql`
	mutation logoutUser {
		logoutUser {
			ok
		}
	}
`

export const CONFIRM_USER_EMAIL = gql`
	mutation confirmUserEmail($token: String!, $uidb64: String!, $email: String!) {
		confirmUserEmail(token: $token, uidb64: $uidb64, email: $email) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const LOGIN_USER_BY_TOKEN = gql`
	mutation loginUserByToken($token: String!, $uidb64: String!) {
		loginUserByToken(token: $token, uidb64: $uidb64) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const REGISTER_USER = gql`
	mutation registerUser(
		$address: LimitedString!
		$city: LimitedString!
		$countryId: ID!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$password1: LimitedString!
		$password2: LimitedString!
		$phoneNumber: LimitedString!
		$postCode: LimitedString!
		$termsOfUse: Boolean!
	) {
		registerUser(
			address: $address
			city: $city
			countryId: $countryId
			firstName: $firstName
			lastName: $lastName
			password1: $password1
			password2: $password2
			phoneNumber: $phoneNumber
			postCode: $postCode
			termsOfUse: $termsOfUse
		) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const RESET_USER_PASSWORD = gql`
	mutation resetUserPassword($email: LowerString!) {
		resetUserPassword(email: $email) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CONFIRM_RESET_USER_PASSWORD = gql`
	mutation confirmResetUserPassword(
		$password1: LimitedString!
		$password2: LimitedString!
		$token: String!
		$uidb64: String!
	) {
		confirmResetUserPassword(
			password1: $password1
			password2: $password2
			token: $token
			uidb64: $uidb64
		) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`
