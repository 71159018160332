import { create } from 'zustand'
import { CustomSelectValue } from 'src/shared/ui/SimpleSelect/types'
import { defaultFilters } from 'src/features/ParentFilters/options'
import { SelectedRow } from 'src/shared/types/common'

export type StatusPayload = 'ALL' | 'ACTIVATED' | 'PENDING'
export type LicenceStatus =
	| 'OPEN'
	| 'IN_PROGRESS'
	| 'PROTECTED'
	| 'UNPROTECTED'
	| 'EXPIRED'
	| 'default'
export type LicenceType = 'PRE_PAID' | 'EXTRA' | 'default'

export type Actions = {
	setSelectedRows: (rows: SelectedRow[]) => void
	setPage: (page: number) => void
	setPerPage: (perPage: number) => void
	setSearch: (search: string) => void
	setStatus: (status: StatusPayload) => void
	setPolicy: (policy: CustomSelectValue) => void
	setSchool: (policy: CustomSelectValue) => void
	setLicenceStatus: (policy: CustomSelectValue<LicenceStatus>) => void
	setLicenceType: (policy: CustomSelectValue<LicenceType>) => void
	setParentFilters: (
		filters: Pick<ParentsTableStore, 'school' | 'policy' | 'licenceType' | 'licenceStatus'>
	) => void
	setSortByNameDesc: () => void
	setSortByNameAsc: () => void
	setSortByEmailDesc: () => void
	setSortByEmailAsc: () => void
	setShouldUpdate: (shouldUpdate: boolean) => void
	resetStore: () => void
	resetFilters: () => void
}

export type ParentsTableStore = {
	page: number
	perPage: number
	selectedRows: SelectedRow[]
	orderBy: 'NAME' | 'EMAIL' | 'NAME_AND_EMAIL'
	sortBy: 'ASC' | 'DESC'
	status: StatusPayload
	search: string
	school: CustomSelectValue
	policy: CustomSelectValue
	licenceStatus: CustomSelectValue<LicenceStatus>
	licenceType: CustomSelectValue<LicenceType>
	shouldUpdate: boolean
}

export const useParentsTableStore = create<ParentsTableStore & Actions>((set) => ({
	page: 1,
	perPage: 10,
	selectedRows: [],
	orderBy: 'NAME_AND_EMAIL',
	sortBy: 'ASC',
	status: 'ALL',
	search: '',
	school: defaultFilters.school,
	policy: defaultFilters.policy,
	licenceStatus: defaultFilters.licenceStatus,
	licenceType: defaultFilters.licenceType,
	shouldUpdate: false,
	setSelectedRows: (selectedRows: SelectedRow[]) => set(() => ({ selectedRows })),
	setPage: (page: number) => set(() => ({ page, selectedRows: [] })),
	setPerPage: (perPage: number) =>
		set(({ selectedRows, perPage: currentPerPage }) => ({
			perPage,
			page: 1,
			selectedRows: perPage > currentPerPage ? selectedRows : []
		})),
	setSearch: (search: string) => set(() => ({ search, page: 1, selectedRows: [] })),
	setStatus: (status: StatusPayload) => set(() => ({ status, page: 1, selectedRows: [] })),
	setPolicy: (policy: CustomSelectValue) => set(() => ({ policy, page: 1, selectedRows: [] })),
	setSchool: (school: CustomSelectValue) => set(() => ({ school, page: 1, selectedRows: [] })),
	setLicenceStatus: (licenceStatus: CustomSelectValue<LicenceStatus>) =>
		set(() => ({ licenceStatus, page: 1, selectedRows: [] })),
	setLicenceType: (licenceType: CustomSelectValue<LicenceType>) =>
		set(() => ({ licenceType, page: 1, selectedRows: [] })),
	setParentFilters: ({ school, policy, licenceStatus, licenceType }) =>
		set(() => ({
			school,
			policy,
			licenceStatus,
			licenceType
		})),
	setSortByNameDesc: () => set(() => ({ sortBy: 'DESC', orderBy: 'NAME', selectedRows: [] })),
	setSortByNameAsc: () => set(() => ({ sortBy: 'ASC', orderBy: 'NAME', selectedRows: [] })),
	setSortByEmailDesc: () => set(() => ({ sortBy: 'DESC', orderBy: 'EMAIL', selectedRows: [] })),
	setSortByEmailAsc: () => set(() => ({ sortBy: 'ASC', orderBy: 'EMAIL', selectedRows: [] })),
	setShouldUpdate: (shouldUpdate: boolean) => set(() => ({ shouldUpdate })),
	resetStore: () =>
		set(() => ({
			page: 1,
			perPage: 10,
			selectedRows: [],
			orderBy: 'NAME',
			sortBy: 'ASC',
			status: 'ALL',
			search: '',
			school: defaultFilters.school,
			policy: defaultFilters.policy,
			licenceStatus: defaultFilters.licenceStatus,
			licenceType: defaultFilters.licenceType,
			shouldUpdate: false
		})),
	resetFilters: () =>
		set(() => ({
			page: 1,
			school: defaultFilters.school,
			policy: defaultFilters.policy,
			licenceStatus: defaultFilters.licenceStatus,
			licenceType: defaultFilters.licenceType
		}))
}))
